// @flow
import * as React from 'react'
import { Helmet } from 'react-helmet'
import config from 'config'
import { helmetJsonLdProp } from "react-schemaorg"

type Props = {
  pathname: string
  title: string
  description: string
  type: 'profile' | 'website' | 'article'
  noFollow?: boolean
  publishTime?: string
  author?: string
  canonicalUrl?: string
  prevPath?: string | false
  nextPath?: string | false
  rss?: boolean
  appleMobileAppIcon?: boolean
  jsonLd?: ReturnType<typeof helmetJsonLdProp> | ReturnType<typeof helmetJsonLdProp>[]
}

export default function Meta(props: Props) {
  const pathnameWithoutLocale = props.pathname && props.pathname.replace('/de-at', '')
  const schemas = props.jsonLd 
    ? (Array.isArray(props.jsonLd) ? props.jsonLd : [props.jsonLd])
    : []
  return (
    <Helmet
      htmlAttributes={{ lang: 'de' }}
      {...(config.features.structuredData && schemas.length > 0 && { script: schemas })}
    >
      <title>{props.title}</title>
      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
      {props.noFollow ? (
        <meta name="robots" content="noindex" />
      ) : (
        <meta name="robots" content="index,follow" />
      )}
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content={config.siteName} />
      <meta property="og:url" content={`${config.canonicalUrl}${props.pathname}`} />
      <meta property="og:type" content={props.type} />
      <meta property="og:description" content={props.description} />
      {props.publishTime && <meta property="article:published_time" content={props.publishTime} />}
      {props.author && <meta property="article:author" content={props.author} />}
      {props.canonicalUrl ? (
        <link rel="canonical" href={props.canonicalUrl} />
      ) : (
        <link rel="canonical" href={`${config.canonicalUrl}${props.pathname}`} />
      )}
      {props.prevPath && <link rel="prev" href={props.prevPath} />}
      {props.nextPath && <link rel="next" href={props.nextPath} />}
      {props.appleMobileAppIcon && config.features.appleBadgeBanner && (
        <meta
          name="apple-itunes-app"
          content="app-id=1589786739, app-argument=https://www.wundercurves.de"
        />
      )}
    </Helmet>
  )
}
